<template>
  <div class="register-wrapper">
    <div class="head-wrapper">
      <header>
        <router-link to="/">
          <img src="@/static/login/logo.png" alt />
        </router-link>
        <div class="aside-right">
          <router-link to="/home">
            <span>返回首页</span>
          </router-link>
        </div>
      </header>
    </div>

    <section>
      <h1>找回密码</h1>
      <el-steps :active="active">
        <el-step title="选择方式"></el-step>
        <el-step :title="titles"></el-step>
        <el-step title="重置密码"></el-step>
      </el-steps>
      <div v-if="active == '1'">
        <el-form ref="form" :model="form" label-width="0px" class="form">
          <el-form-item>
            <el-radio v-model="radio" label="0">通过手机号找回</el-radio>
            <p class="colors">
              通过当前注册手机号获取短信验证码方式找回密码
            </p></el-form-item
          >

          <el-form-item>
            <el-radio v-model="radio" label="1">通过申诉找回</el-radio>
            <p class="colors">如果您忘记当前注册手机号，请通过申诉方式找回</p>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="w420" @click="nextStep(radio)"
              >下一步</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-if="(active == '2') & (iphones == '1')">
        <el-form
          ref="form"
          :model="form"
          label-width="0px"
          class="form"
          :rules="phonerules"
        >
          <el-form-item prop="iphone">
            <el-input
              placeholder="请输入手机号"
              v-model="form.iphone"
              class="w420"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="iphoneCode">
            <el-input
              v-model="form.iphoneCode"
              placeholder="请输入短信验证码"
              @keyup.enter.native="loginByphone('form')"
              auto-complete="off"
              class="w420"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
            >
              <template slot="append">
                <el-button
                  @click="sendCheckCode"
                  class="sendcode"
                  :class="{ 'disabled-style': getCodeBtnDisable }"
                  :disabled="getCodeBtnDisable"
                  >{{ btntxt }}
                </el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="flext w420">
              <el-button @click="active = 1" class="shang">上一步</el-button>
              <el-button type="primary" @click="modifyMobile('form')"
                >下一步</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="(active == '2') & (applys == '1')">
        <el-form
          ref="form"
          :model="form"
          label-width="0px"
          class="form"
          :rules="phonerules"
        >
          <el-form-item prop="name">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入真实姓名（2-15个汉字）"
            ></el-input>
          </el-form-item>
          <el-form-item prop="dcNumber">
            <el-input
              v-model.trim="form.dcNumber"
              placeholder="请输入有效身份证号码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="distype">
            <MulSelects
              :code="'disabled_type'"
              class="420"
              placeholder="请选择残疾类别"
              @setMValue="setSelectValue($event, 'distype')"
              :defaultValue="form.distype"
            ></MulSelects>
          </el-form-item>
          <el-form-item prop="dislv">
            <MulSelects
              :code="'disabled_level2'"
              class="420"
              placeholder="请选择残疾等级"
              @setMValue="setSelectValue($event, 'dislv')"
              :defaultValue="form.dislv"
            ></MulSelects>
          </el-form-item>
          <el-form-item prop="iphone">
            <el-input
              placeholder="请输入手机号"
              v-model="form.iphone"
              class="w420"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
            >
              <!-- <template slot="prepend">+86</template> -->
            </el-input>
          </el-form-item>
          <el-form-item prop="iphoneCode">
            <el-input
              v-model="form.iphoneCode"
              placeholder="请输入短信验证码"
              @keyup.enter.native="loginByphone('form')"
              auto-complete="off"
              class="w420 "
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
            >
              <template slot="append">
                <el-button
                  @click="sendCheckCode"
                  class="sendcode"
                  :class="{ 'disabled-style': getCodeBtnDisable }"
                  :disabled="getCodeBtnDisable"
                  >{{ btntxt }}
                </el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="flext w420 marT10">
              <el-button @click="active = 1" class="shang">上一步</el-button>
              <el-button type="primary" @click="modifyApply(form)"
                >下一步</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="active == '3'">
        <el-form
          ref="form2"
          :model="form2"
          :rules="err"
          label-width="0px"
          class="form paddT20 paddB20"
        >
          <el-form-item prop="newPass">
            <el-input
              placeholder="请输入新登录密码"
              type="password"
              v-model="form2.newPass"
              @focus="passHandle"
              class="w420"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
            ></el-input>
            <div class="flex littlogo" v-if="isPass">
              <img src="~static/login/littlelogo.png" alt="" />
              <p>
                您的密码必须包含至少6~18个字符和字母数字组合
                尽量避免使用生日等易于被他人猜到的密码
              </p>
            </div>
          </el-form-item>
          <el-form-item prop="reNewPass">
            <el-input
              placeholder="请确认新密码"
              type="password"
              v-model="form2.reNewPass"
              class="w420"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item class="marT50">
            <el-button
              type="primary"
              class="bs_btn_oo w420"
              @click="modifyPassword('form')"
              >确认修改</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </section>
  </div>
</template>

<script>
import MulSelects from "components/selectComponents/MulSelects";
export default {
  components: {
    MulSelects,
  },
  data() {
    return {
      isPass: false,
      applys: "0",
      iphones: "0",
      title: "注册",
      titles: "手机验证",
      radio: "0",
      active: 1,
      btntxt: "发送验证码",
      time: 181,
      getCodeBtnDisable: false,
      i: '<i class="el-icon-d-arrow-right"></i>',
      form: {
        iphoneCode: "",
        iphone: "",
      },
      form2: {
        newPass: "",
        reNewPass: "",
      },
      err: {
        newPass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
            message: "密码必须为8位以上，且必须包含数字和字母",
            trigger: "blur",
          },
        ],
        reNewPass: [
          { required: true, message: "请确认密码", trigger: "blur" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
            message: "密码必须为8位以上，且必须包含数字和字母",
            trigger: "blur",
          },
        ],
      },
      iphone: "",
      phonerules: {
        iphone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        dcNumber: [
          {
            required: true,
            message: "您的身份信息有误请重新输入",
            trigger: "blur",
          },
        ],
        distype: [
          {
            required: true,
            message: "请保证残疾类别与残疾证上一致",
            trigger: "blur,change",
          },
        ],
        dislv: [
          {
            required: true,
            message: "请保证残疾等级与残疾证上一致",
            trigger: "blur,change",
          },
        ],
        name: [
          {
            required: true,
            message: "您的姓名有误请重新输入",
            trigger: "blur",
          },
        ],
        iphoneCode: [
          { required: true, message: "请输入收到的验证码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    nextStep(val) {
      console.log(val, "Hohoiho");
      if (val == 0) {
        this.iphones = "1";
      } else {
        this.applys = "1";
        this.titles = "申诉找回";
      }
      this.active = 2;
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    // 发送验证码
    async sendCheckCode() {
      let phoneNum = this.form.iphone;
      this.getCodeBtnDisable = true;
      if (!this.form.iphone) {
        this.$message.error("请输入手机号码");
        this.getCodeBtnDisable = false;
        return;
      }
      if (this.getCodeBtnDisable) {
        this.$axios
          .post(
            "/api/app-jycy-phoneverificationcode/phoneVerification/" + phoneNum
          )
          .then((res) => {
            if (res.data.success) {
              this.$message.success({
                message: "验证码已经发送至您的手机，请注意接收",
              });
              this.time = 180;
              this.timer();
            } else {
              this.getCodeBtnDisable = false;
              this.$message.error({
                message: res.data.msg
              });
            }
          });
      }
    },
    //确认下一步
    modifyMobile() {
      if (!this.form.iphone) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.form.iphoneCode) {
        this.$message.error("请输入收到的验证码");
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.active++ > 2) this.active = 0;
        }
      });
    },
    modifyApply(form) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = await this.$api.verifyDisabledCode(form);
          if (res.data.success) {
            console.log(res);
            if (this.active++ > 2) this.active = 0;
          } else {
            this.$message.warning(res.data.msg);
          }
        }
      });
    },
    // 确认修改密码
    modifyPassword(form) {
      this.$refs["form2"].validate((valid) => {
        if (valid) {
          if (this.form2.newPass != this.form2.reNewPass) {
            this.$message({
              message: "两次密码不一致，请重新输入",
              center: true,
              type: "warning",
            });
            this.form2.reNewPass = "";
          } else {
            let params = {
              pwd: this.form2.newPass,
              iphone: this.form.iphone,
              iphoneCode: this.form.iphoneCode,
            };
            let that = this;
            that.$api.findPwd(params).then(function(res) {
              if (!res.data.success) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message({
                  message: "密码修改成功",
                  center: true,
                  type: "success",
                });
                if (this.active++ > 2) this.active = 0;
                that.form = {};
                this.$router.push("/Login");
              }
            });
          }
        }
      });
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.getCodeBtnDisable = false;
        console.log(this.time);
      }
    },
    passHandle() {
      this.isPass = true;
    },
  },

  mounted() {
    document.title = "找回密码_中国残联就业创业服务平台";
  },
  created() {
    // this.getUserInfo();
  },
};
</script>
<style lang="less" scoped>
@import "~assets/css/findPassword";
.colors {
  color: #999;
}
.shang {
  border: 1px solid @00;
  color: #00924b;
}
.el-form-item {
  margin-bottom: 15px !important;
}

/deep/ .el-input__inner {
  background-color: transparent;
  border: 1px solid #cecfd0;
}
/deep/ .el-input--suffix .el-input__inner {
  width: 420px;
}
/deep/ .el-input {
  .el-input-group__append {
    color: #00924c;
    border-left: none;
    background-color: transparent;
  }
}

/deep/ .el-button--info {
  height: 100%;

  /deep/ .el-loading-spinner i {
    color: #ffffff;
    margin-right: 10px;
    background: #00924c;
  }

  .el-loading-spinner {
    background: #00924c;
    display: flex;
    height: 100%;
    line-height: 100%;
    justify-content: center;
    align-items: center;

    .el-loading-text {
      color: #ffffff;
    }
  }
}
.passinput {
  position: relative;
}
.littlogo {
  display: flex;
  position: absolute;
  left: 390px;
  width: 75%;
  top: -3px;
  z-index: 555;
  background: #ffffff;
  padding: 5px;
  img {
    width: 14px;
    height: 14px;
    display: block;
    line-height: 36px;
    margin-right: 6px;
    margin-top: 3px;
  }

  p {
    padding: 0px;
    line-height: 20px;
    font-size: 12px;
  }
}
</style>
